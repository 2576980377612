import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./styles.css"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: 400,
    bgcolor: '#EFEFEF',
    border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function ModalPequeno({ open, handleClose, handleupdated, toDelete}) {
    
    function apagar() {
        fetch("https://nathivos.com.br/backend/delete/u446777955_app/sub_post/where/id/"+toDelete.id)
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
            }
            handleupdated()
        })
    }

    return (
        <div key={Math.random()}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">{toDelete === null ? "Nathivos":"Deseja realmente excluir?"}</Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='ed_modal_content'>
                        <p className='mp_card_text'>{toDelete?.id} =&gt; {toDelete?.content}</p>
                        <div className="ed_button">
                            <button className="ed_btn_apagar" onClick={()=>{apagar();} }>Excluir</button>
                            <button className="ed_btn_cancel" onClick={() => {handleClose(false) }}>Cancelar</button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
