import React, { useEffect, useState } from "react";
import "./styles.css"

const AddNewPost = () => {
    const initialState = { name_pt: "", name_en: "", type: "", subtype: "", photo: "", description_pt: "", description_en: "", status: 0, link:""}
    const [input, setInput] = useState(initialState)
    const initial_State = [{ id: null, name_pt: "", name_en: "" }]
    const [menu, setMenu] = useState(initial_State)
    const [submenu, setSubmenu] = useState([{ id: null, id_menu: "", name_pt: "", name_en: "" }])
    
    
    useEffect(() => {
        fetch("https://nathivos.com.br/backend/select/u446777955_app/main_menu")
            .then(r => r.json())
            .then(r => {
                setMenu(r)
            })
    }, [])
    useEffect(() => {
        fetch("https://nathivos.com.br/backend/select/u446777955_app/sub_menu/where/link_menu/igual/" + input.type)
            .then(r => r.json())
            .then(r => {
                console.log(r);
                
                if (r.status !== "erro") {
                    setSubmenu(r)
                }
                else {
                    setSubmenu([{ id: null, id_menu: "", name_pt: "", name_en: "", link:""}])
                }
            })
    }, [input.type]) 


    function salvar() {
        const data = new FormData()
        for (const property in input) {
            if (input[property] !== "") {
                data.append(property, input[property])
            }
        }
        fetch("https://nathivos.com.br/backend/insert/u446777955_app/main_post/", {
            method: "POST",
            body: data
        })
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert("Não foi possível Salvar")
            } else {
                alert("Salvo com sucesso")
                setInput(initialState)
            }
        })
    }

    return (
        <div className="ed_addcontainer">
            <div className="ed_form">
                <div className="ed_title">Criar nova postagem.</div>
                <div className="ed_input2">
                    <label htmlFor="">Nome - pt-Br</label>
                    <input type="text" className="ed_input" onChange={(e) => { setInput({ ...input, name_pt: e.target.value }) }} value={input.name_pt} />
                </div>
                <div className="ed_input2">
                    <label htmlFor="">Nome - en</label>
                    <input type="text" className="ed_input" onChange={(e) => { setInput({ ...input, name_en: e.target.value }) }} value={input.name_en} />
                </div>
                <div className="ed_input1">
                    <label htmlFor="">Link (Tudo letra minúscula, sem assentos e trocar espaços por - )</label>
                    <input type="text" className="ed_input" onChange={(e) => { setInput({ ...input, link: e.target.value }) }} value={input.link} />
                </div>
                <div className="ed_input3">
                    <label htmlFor="">Tipo</label>
                    <select className="ed_input" onChange={(e) => { setInput({ ...input, type: e.target.value }) }} value={input.type || ""}>
                        <option value="" selected >Selecione...</option>
                        {menu?.map(item => <option value={item.link} key={Math.random()}>{item.name_pt}</option>)}
                    </select>
                </div>
                <div className="ed_input3">
                    <label htmlFor="">Subtipo</label>
                    <select type="text" className="ed_input" onChange={(e) => { setInput({ ...input, subtype: e.target.value }) }} value={input.subtype || ""}>
                        {submenu?.map(item => <option value={item.link_menu} key={Math.random()}>{item.name_pt}</option>)}
                    </select>
                </div>
                <div className="ed_input3">
                    <label htmlFor="">Status</label>
                    <select type="text" className="ed_input" onChange={(e) => { setInput({ ...input, status: e.target.value }) }} value={input.status}>
                        <option value="0">Offline</option>
                        <option value="1">Online</option>
                    </select>
                </div>
                <div className="ed_inputlg">
                    <label htmlFor="">Descrição Pt</label>
                    <textarea className="ed_textarea" cols="30" rows="10" onChange={(e) => { setInput({ ...input, description_pt: e.target.value }) }} value={input.description_pt} />
                </div>
                <div className="ed_inputlg">
                    <label htmlFor="">Descrição En</label>
                    <textarea className="ed_textarea" cols="30" rows="10" onChange={(e) => { setInput({ ...input, description_en: e.target.value }) }} value={input.description_en} />
                </div>
                <div className="ed_inputxlrow">
                    <div className="ed_inputimgselect">
                        <label htmlFor="">Foto</label>
                        <input type="file" accept="image/*" onChange={(e) => { setInput({ ...input, photo: e.target.files[0] }) }} />
                    </div>
                    <div className="ed_imgselected">
                        <img className="edit_photo" alt="" src={(input.photo && URL.createObjectURL(input.photo))} />
                    </div>
                </div>
                <div className="ed_button">
                    <button className="ed_btn_salvar" onClick={salvar}>Salvar</button>
                    <button className="ed_btn_cancel" onClick={() => setInput(initialState)}>Cancelar</button>
                </div>
            </div>
        </div>
    )
}
export default AddNewPost