import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./styles.css"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: 400,
    bgcolor: '#EFEFEF',
    border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 3,
};

export default function ModalAddFotos({ open, handleClose, toAdd, handleupdated }) {
    const [input, setInput] = useState({photo: "", alt:"", legend:""})

    const salvar = ()=>{
        if (input.photo === "" || input.alt === "" || input.legend === "") {
            alert("Faltou adicionar conteúdo")
            return
        }
        const data = new FormData()
        data.append("id_fcont", toAdd.id)
        for (const property in input) {
            if (input[property] !== "") {
                data.append(property, input[property])
            }
        }
        // fetch(toUpdate === null ? "https://nathivos.com.br/backend/insert/u446777955_app/sub_post" : "https://nathivos.com.br/backend/update/u446777955_app/sub_post/where/id/" + toUpdate.id, {
        fetch("https://nathivos.com.br/backend/insert/u446777955_app/photos", {
            method: "POST",
            body: data
        })
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg, " = ", r.id)
                setInput({photo: "", alt:"", legend:""})
                handleClose(false)
                handleupdated()
            }
        })   
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title2"
                aria-describedby="modal-modal-description2"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">{toAdd?.content}</Typography>
                    <div id="modal-modal-description"  className='ed_modal_content'>
                        <div className="ed_input1">
                            <label htmlFor="ed_input">Nome da foto</label>
                            <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, alt: e.target.value }) }} value={input.alt} />
                        </div>
                        <div className="ed_input1">
                            <label htmlFor="ed_input">Legenda</label>
                            <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, legend: e.target.value }) }} value={input.legend} />
                        </div>
                        <div className="ed_inputimgcarrousel">
                            <input type="file" accept="image/*" onChange={(e) => setInput({ ...input, photo: e.target.files[0] })} />
                        </div>
                        <div className="ed_imgmini" key={Math.random()}>
                            {input.photo && <img src={input.photo && URL.createObjectURL(input.photo)} alt="" />}
                        </div>
                        <div className="ed_button">
                            <button className="ed_btn_salvar" onClick={salvar}>Salvar</button>
                            <button className="ed_btn_cancel" onClick={() => { handleClose(false); setInput({photo: "", alt:"", legend:""})}}>Cancelar</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
