import React from "react";


const NotFound = ()=>{
    return(
        <div>
            Not Found 404
        </div>
    )
}
export default NotFound;