// src/components/Login.js
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../services/authService';
import "./styleLogin.css"

function Login() {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [email, setEmail] = useState('');
//   const [email, setEmail] = useState('ivobiesdorf@gmail.com');
  const [password, setPassword] = useState('');
//   const [password, setPassword] = useState('neguinha2017');
  const [msg, setMsg] = useState("")
  const navigate = useNavigate();

    const handleLogin = () => {
        try {
            const formdata = new FormData()
            formdata.append("email", email)
            formdata.append("password", password)

            fetch("https://nathivos.com.br/backend/login/u446777955_app/users",{
                method: "POST",
                body: formdata
            })
            // .then(r => r.text())
            // .then(r => console.log(r))
            .then(r => r.json())
            .then(r => {
                if (r.status === "sucesso") {
                    setIsAuthenticated({...isAuthenticated, status:true, nome: r.nome, email: r.email});
                    navigate('/');
                    setMsg(r.msg)
                } else {
                    setMsg(r.msg)
                }
            })
        } catch (error) {
            console.log(error);
        }
    };

  return (
    <div className='form-login'>
    <h2>Gerenciador do site Nathivo's</h2>
    <div >
        <label>E-Mail</label>
        <input
            type="email"
            placeholder="Username"
            value={email}
            onChange={(e) => {setEmail(e.target.value); setMsg("")}}
        />
        <label>Senha</label>
        <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {setPassword(e.target.value); setMsg("")}}
        />
        <div className='form-login-div-button'>
            <button onClick={handleLogin}>Entrar</button>
        </div>
        <div>{msg}</div>
    </div>
</div>
  );
}

export default Login;
