import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./styles.css"
// import ModalExcluir from './ModalExcluir';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: 800,
    bgcolor: '#EFEFEF',
    border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function ModalAddSub({ open, handleClose, posttoadd, handleupdated, toUpdate }) {
    const initialState = { link_post: posttoadd, content_type: "", content: "", aux: "", content_en: "" }
    const [input, setInput] = useState(initialState)
    

    useEffect(() => {
        setInput(toUpdate || initialState)
    }, [toUpdate])

    function salvar() {
        if(input.content_type === "foto"){
            if(input.aux === ""){
                alert("Faltou adicionar a foto")
                return
            }
        }else if (input.content === "") {
            alert("Faltou adicionar conteúdo")
            return
        }
        const data = new FormData()
        for (const property in input) {
            if (input[property] !== "") {
                data.append(property, input[property])
            }
        }

        fetch(toUpdate === null ? "https://nathivos.com.br/backend/insert/u446777955_app/sub_post" : "https://nathivos.com.br/backend/update/u446777955_app/sub_post/where/id/" + toUpdate.id, {
            method: "POST",
            body: data
        })
        .then(r => r.json())
        .then(r => {
            if (r.status === "erro") {
                alert(r.msg)
            } else {
                alert(r.msg)
                setInput(initialState)
                handleupdated()
            }
        })   
    }
    
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">{toUpdate === null ? "Adicione algo legal" : "Atualize os dados"}</Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='ed_modal_content'>
                        <div className="ed_input1">
                            <label htmlFor="">Componente</label>
                            <select className="ed_input" onChange={(e) => { setInput({ ...input, content_type: e.target.value }) }} value={input.content_type}>
                                <option value="">           Selecione...    </option>
                                <option value="title1">     Título 1        </option>
                                <option value="title2">     Título 2        </option>
                                <option value="title3">     Título 3        </option>
                                <option value="paragrafo">  Parágrafo       </option>
                                <option value="lista">      Lista           </option>
                                <option value="foto">       Foto            </option>
                                <option value="mapa">       Mapa do google  </option>
                                <option value="video">      Vídeo YouTube   </option>
                                <option value="fotos">      Mural de fotos  </option>
                                <option value="linkinterno">Link interno    </option>
                                <option value="linkexterno">Link externo    </option>
                            </select>
                        </div>
                        {(input.content_type === "title1" || input.content_type === "title2" || input.content_type === "title3") &&
                            <>
                                <div className="ed_input1">
                                    <label htmlFor="">Conteúdo</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content: e.target.value }) }} value={input.content} />
                                </div>
                                <div className="ed_input1">
                                    <label htmlFor="">Conteúdo em inglês</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content_en: e.target.value }) }} value={input.content_en} />
                                </div>
                            </>
                        }
                        {(input.content_type === "paragrafo" || input.content_type === "lista"  || input.content_type === "tabela") &&
                            <>
                                <div className="ed_inputlg">
                                    <label htmlFor="">Conteúdo {input.content_type === "lista" && "(Enter para novo item da lista)"}</label>
                                    <textarea className='ed_input_textarea' name="" id="" cols="30" rows="10" onChange={(e) => { setInput({ ...input, content: e.target.value }) }} value={input.content} />
                                </div>
                                <div className="ed_inputlg">
                                    <label htmlFor="">Conteúdo em inglês {input.content_type === "lista" && "(Enter para novo item da lista)"}</label>
                                    <textarea className='ed_input_textarea' name="" id="" cols="30" rows="10" onChange={(e) => { setInput({ ...input, content_en: e.target.value }) }} value={input.content_en} />
                                </div>
                            </>
                        }
                        {(input.content_type === "foto") &&
                            <>
                                <div className="ed_input1">
                                    <label htmlFor="">Foto</label>
                                    <input type="file" accept="image/*" onChange={(e) => { setInput({ ...input, aux: e.target.files[0] }) }} />
                                </div>
                                <div className="ed_input1">
                                    <label htmlFor="">Legenda</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content: e.target.value }) }} value={input.content} />
                                </div>
                                <div className="ed_input1">
                                    <label htmlFor="">Legenda em inglês</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content_en: e.target.value }) }} value={input.content_en} />
                                </div>
                            </>
                        }
                        {(input.content_type === "mapa" || input.content_type === "video") &&
                            <div className="ed_input1">
                                <label htmlFor="">Link de incorporar</label>
                                <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content: e.target.value }) }} value={input.content} />
                            </div>
                        }
                        {(input.content_type === "fotos") &&
                            <>
                                <div className="ed_input1">
                                    <label htmlFor="">Nome do mural de fotos</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content: e.target.value }) }} value={input.content} />
                                </div>
                                <div className="ed_input1">
                                    <label htmlFor="">Nome do mural de fotos em inglês</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content_en: e.target.value }) }} value={input.content_en} />
                                </div>
                            </>
                        }

                        {(input.content_type === "linkinterno" || input.content_type === "linkexterno") &&
                            <>
                                <div className="ed_input2">
                                    <label htmlFor="">Texto do link</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content: e.target.value }) }} value={input.content} />
                                </div>
                                <div className="ed_input2">
                                    <label htmlFor="">Texto do link em inglês</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, content_en: e.target.value }) }} value={input.content_en} />
                                </div>
                                <div className="ed_input1">
                                    <label htmlFor="">Link</label>
                                    <input type="text" className='ed_input' onChange={(e) => { setInput({ ...input, aux: e.target.value }) }} value={input.aux} />
                                </div>
                            </>
                        }

                        <div className="ed_button">
                            <button className="ed_btn_salvar" onClick={() => { salvar(); }}>Salvar</button>
                            <button className="ed_btn_cancel" onClick={() => { setInput(initialState); handleClose(false) }}>Cancelar</button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
