import React, { useEffect, useState } from "react";
import "./styles.css"

const UpdatePost = () => {
    const initialState = { name_pt: "", name_en: "", link:"", type: "", subtype: "", photo: "", description_pt: "", description_en: "", status: "" }
    const initial_State = [{ id: null, name_pt: "", name_en: "" }]
    const [input, setInput] = useState(initialState)
    const [posts, setPosts] = useState([{}])
    const [toupdate, setToupdate] = useState({});
    const [todelete, setTodelete] = useState({});
    const [status, seStatus] = useState("")
    const [updated, setUpdated] = useState(1)
    const FOTOS = "https://nathivos.com.br/backend/fotos/"
    const [menu, setMenu] = useState(initial_State)
    const [submenu, setSubmenu] = useState([{ id: null, id_menu: "", name_pt: "", name_en: "" }])
    const handleedit = (p)=>{ 
        setToupdate(p); 
        setTodelete({}); 
        setInput(initialState) 
    }
    const handledelete = (p)=>{
        setTodelete(p); 
        setToupdate({});
    }
    useEffect(() => {
        fetch("https://nathivos.com.br/backend/select/u446777955_app/main_menu")
            .then(r => r.json())
            .then(r => {
                setMenu(r)
            })
    }, [])
    useEffect(() => {
        const url = input.type === "" ? toupdate.type : input.type
        
        fetch("https://nathivos.com.br/backend/select/u446777955_app/sub_menu/where/link_menu/igual/" + url)
        .then(r => r.json())
        .then(r => {
            if (r.status !== "erro") {
                setSubmenu(r)
            }else {
                setSubmenu([{ id: null, link_menu: "", name_pt: "", name_en: "", link:"" }])
            }
        })
        
    }, [input.type, toupdate.type ])

    useEffect(() => {
        fetch("https://nathivos.com.br/backend/select/u446777955_app/main_post/")
            .then(r => r.json())
            .then(r => setPosts(r))
    }, [updated])
    
    function salvar() {
        const data = new FormData()
        for (const property in input) {
            input[property] !== "" && data.append(property, input[property])
            console.log(property," -> ", input[property]);
            
        }
        fetch("https://nathivos.com.br/backend/update/u446777955_app/main_post/where/id/" + toupdate.id, {
            method: "POST",
            body: data
        })
            .then(r => r.json())
            .then(r => {
                if (r.status === "sucesso") {
                    alert("Salvo com sucesso")
                    setToupdate({})
                    setUpdated(updated + 1)
                } else {
                    alert("Não foi possível Atualizar")
                }
            })
    }
    function apagar(id){
        fetch("https://nathivos.com.br/backend/delete/u446777955_app/main_post/where/id/"+id)
        .then(r => r.json())
        .then(r => {
            if(r.status === "sucesso"){
                seStatus(r)
                setUpdated(updated + 1)
            }else{
                seStatus(r)
            }
        })
    }
    return (
        <>
            <div className="ed_lista">
                {!!posts && posts.map((p, i) =>
                    <div className={toupdate.id === p.id ? "ed_listitem_active":"ed_listitem"} key={i} >
                        <img src={FOTOS + p.photo} alt="" className="ed_listphoto" />
                        <b className="ed_listName">{p.name_pt}</b>
                        <div className="ed_btnscontainer">
                            <button className="ed_btn_edit" onClick={() => {handleedit(p)}}>
                                <i className="fa fa-edit fa-lg" aria-hidden="true"> </i>
                            </button>
                            <button className="ed_btn_delete" onClick={() => {handledelete(p)}}>
                                <i className="fa fa-trash fa-lg" aria-hidden="true"> </i>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {!!toupdate.id && <div className="ed_updatecontainer">
                <div className="ed_form">
                    <div className="ed_title">Editar postagem</div>
                    <div className="ed_input2">
                        <label htmlFor="">Nome  pt-Br</label>
                        <input type="text" className="ed_input" onChange={(e) => { setInput({ ...input, name_pt: e.target.value }); setToupdate({ ...toupdate, name_pt: "" }) }} value={input.name_pt || toupdate.name_pt} />
                    </div>
                    <div className="ed_input2">
                        <label htmlFor="">Nome - en</label>
                        <input type="text" className="ed_input" onChange={(e) => { setInput({ ...input, name_en: e.target.value }); setToupdate({ ...toupdate, name_en: "" }) }} value={input.name_en || toupdate.name_en} />
                    </div>
                    <div className="ed_input1">
                        <label htmlFor="">Link (Tudo letra minúscula, sem assentos e trocar espaços por - )</label>
                        <input type="text" className="ed_input" onChange={(e) => { setInput({ ...input, link: e.target.value }); setToupdate({ ...toupdate, link: "" }) }} value={input.link  || toupdate.link} />
                    </div>
                    <div className="ed_input3">
                        <label htmlFor="">Tipo</label>
                        <select className="ed_input" onChange={(e) => { setInput({ ...input, type: e.target.value }) }} value={input.type || toupdate.type}>
                            <option value="" selected >Selecione...</option>
                            {menu?.map(item => <option value={item.link} key={Math.random()}>{item.name_pt}</option>)}
                        </select>
                    </div>
                    <div className="ed_input3">
                        <label htmlFor="">Subtipo</label>
                        <select type="text" className="ed_input" onChange={(e) => { setInput({ ...input, subtype: e.target.value }); setToupdate({ ...toupdate, subtype: "" }) }} value={input.subtype || toupdate.subtype}>
                            <option value="" selected >Selecione...</option>
                            {submenu?.map(item => <option value={item.link} key={Math.random()}>{item.name_pt}</option>)}
                        </select>
                    </div>
                    <div className="ed_input3">
                        <label htmlFor="">Status</label>
                        <select type="text" className="ed_input" onChange={(e) => { setInput({ ...input, status: e.target.value }); setToupdate({ ...toupdate, status: "" }) }} value={input.status || toupdate.status}>
                            <option value="0">Offline</option>
                            <option value="1">Online</option>
                        </select>
                    </div>
                    <div className="ed_inputlg">
                        <label htmlFor="">Descrição Pt</label>
                        <textarea className="ed_textarea" cols="30" rows="10" onChange={(e) => { setInput({ ...input, description_pt: e.target.value }); setToupdate({ ...toupdate, description_pt: "" }) }} value={input.description_pt || toupdate.description_pt} />
                    </div>
                    <div className="ed_inputlg">
                        <label htmlFor="">Descrição En</label>
                        <textarea className="ed_textarea" cols="30" rows="10" onChange={(e) => { setInput({ ...input, description_en: e.target.value }); setToupdate({ ...toupdate, description_en: "" }) }} value={input.description_en || toupdate.description_en} />
                    </div>
                    <div className="ed_inputxlrow">
                        <div className="ed_inputimgselect">
                            <label htmlFor="">Foto</label>
                            <input type="file" accept="image/*" onChange={(e) => { setInput({ ...input, photo: e.target.files[0] }) }} />
                        </div>
                        <div className="ed_imgselected">
                            <img className="edit_photo" alt="" src={(input.photo && URL.createObjectURL(input.photo)) || FOTOS + toupdate.photo} />
                        </div>
                    </div>
                    <div className="ed_button">
                        <button className="ed_btn_salvar" onClick={salvar}>Salvar</button>
                        <button className="ed_btn_cancel" onClick={() => setToupdate({})}>Cancelar</button>
                    </div>
                </div>
            </div>}
            {!!todelete.id && <div className="ed_updatecontainer">
                <div className="ed_excluir">
                    {status === "" ? (
                        <>
                            <div className="ed_title_excluir">Excluir <b>{todelete.name_pt}</b> com tudo o que faz parte dele? </div>
                            <div className="ed_btns_excluir">
                                <button className="ed_btn_cancel" onClick={() => setTodelete({})}> Cancelar</button>
                                <button className="ed_btn_excluir" onClick={() => {apagar(todelete.id)}}>Sim, quero excluir </button>
                            </div>
                        </>):(
                            <>
                                <div className="ed_title2">{status.msg} </div>
                                <div className="ed_btns_excluir">
                                    <button className="ed_btn_cancel" onClick={() => {setTodelete({});seStatus("")}}>Fechar</button>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>}
        </>
    )
}
export default UpdatePost