import React, { useContext, useState } from "react";
import "./styleEditor.css"
import AddNewPost from "../components/AddNewPost"
import UpdatePost from "../components/UpdatePost"
import AddSubPost from "../components/AddSubPost"
import ManagerMenu from "../components/ManagerMenu"
import { AuthContext } from '../services/authService';


const Home = () => {
    const [pg, setPg] = useState("menu")
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

    const handleLogout = () => {
       setIsAuthenticated({...isAuthenticated, status:false});
    };

    return (
        <div className="ed_content" >
            <div className="ed_barramenuslateral">
                <button onClick={()=>setPg("menu")} className={pg === "menu" ? "ed_menuitem_active":"ed_menuitem"}title="Editar, adicionar ou excluir item da Barra de navegação"> Menu</button>
                <button onClick={()=>setPg("novo")} className={pg === "novo" ? "ed_menuitem_active":"ed_menuitem"}title="Criar um novo Post"> Novo Post</button>
                <button onClick={()=>setPg("editar")} className={pg === "editar" ? "ed_menuitem_active":"ed_menuitem"}title="Listar os Posts existentes e editar"> Listar e Editar</button>
                <button onClick={()=>setPg("add")} className={pg === "add" ? "ed_menuitem_active":"ed_menuitem"}title="Adicionar item a um Post já existente">Add</button>
                <button onClick={handleLogout} className="ed_menuitem">{isAuthenticated.nome} <i className="fa fa-sign-out" aria-hidden="true"></i> Sair</button>
            </div>
            <div className="ed_corpoeditor">
                {pg === "menu" && <ManagerMenu/>}
                {pg === "novo" && <AddNewPost/>}
                {pg === "editar" && <UpdatePost/>}
                {pg === "add" && <AddSubPost/>}
            </div>
            
        </div>
    )
}
export default Home;
