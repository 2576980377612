import React, { useEffect, useState } from "react";
import "./styles.css"
import ModalAddSub from "./ModalAddSub"
import ModalPequeno from "./ModalPequeno"
import ModalExcluir from "./ModalExcluir";
import { Link } from "react-router-dom";
import ModalAddFotos from './ModalAddFotos';


const UpdatePost = () => {
    const [listaf, setListaf] = useState([{}])
    const [posts, setPosts] = useState(null)
    const [subposts, setSubposts] = useState(null)
    const [updatemode, setUpdatemode] = useState(false);
    const [posttoadd, setPosttoadd] = useState(null);
    const [updated, setUpdated] = useState(1)
    const [open, setOpen] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [toUpdate, setToUpdate] = useState(null)
    const [toDelete, setToDelete] = useState(null)
    const [fotoDelete, setFotoTodelete] = useState(null)
    const handleOpen = () => setOpen(true);
    const handleOpenP = () => setOpenP(true);
    const handleClose = () => { setOpen(false); setToUpdate(null) };
    const handleCloseP = () => { setOpenP(false); setToDelete(null) };
    const FOTOS = "https://nathivos.com.br/backend/fotos/"
    // const [fotois, setFotois] = useState([{}])
    const [openmd, setOpenmd] = useState(false);
    const [openme, setOpenme] = useState(false);
    const [toadd, setToadd] = useState(null)
    const handleOpenmd = () => setOpenmd(true);
    const handleClosemd = () => { setOpenmd(false); setToadd(null) }
    const handleOpenme = () => setOpenme(true);
    const handleCloseme = () => { setOpenme(false); setFotoTodelete(null) }

    

    // ****************** CARREGA MAIN_POST ***************************
    useEffect(() => {
        try {
            fetch("https://nathivos.com.br/backend/select/u446777955_app/main_post/")
                .then(r => r.json())
                .then(r => {
                    r.status === "erro" ? (console.log("Nenhum registro")) : (setPosts(r))
                })
        } catch (error) {

        }
    }, [])

    // ****************** CARREGA SUB_POST ****************************
    useEffect(() => {
        if (!posttoadd) { return }
        try { 
            fetch("https://nathivos.com.br/backend/select/u446777955_app/sub_post/where/link_post/igual/" + posttoadd)
            .then(r => r.json())
            .then(r => {
                r.status === "erro" ? (setSubposts(null)) : (setSubposts(r))
            })
        } catch (error) {

        }
    }, [updated, posttoadd])

    // ****************** CARREGA FotoS ****************************
    useEffect(()=>{
        try {
            fetch("https://nathivos.com.br/backend/select/u446777955_app/photos")
            .then(r => r.json())
            .then(r => {
                if (r.status === "erro") {
                    return
                } else {
                    setListaf(r)
                }
            })
        } catch (error) {
            
        }
    },[updated])
    
    const handleupdated = () => {
        setUpdated(updated + 1)
    }

    const title1 = el => <h1 className="np_title1" key={el?.id}> {el?.content}</h1>
    const title2 = el => <h2 className="np_title2" key={el?.id}> {el?.content}</h2>
    const title3 = el => <h4 className="np_title3" key={el?.id}> {el?.content}</h4>
    const paragrafo = el => <p className="np_paragrafo" key={el?.id}> {el?.content}</p>
    const foto = el => <div key={el?.id} className="np_divfoto">
        <img className="np_foto" src={FOTOS + el.aux} alt="" />
        <legend className="np_legend">{el.content}</legend>
    </div>
    const listFotos = el => <div key={el?.id} className="np_divfoto">
            <div className="ed_coringacol">
                <h4>{el.content}</h4>
                <div className="ed_coringarow">
                    <div className="ed_listafotos">
                        {listaf && listaf.filter((item)=>item.id_fcont === el.id).map((e, i)=> 
                            <div className="ed_imgmini" key={i} onClick={()=>{setFotoTodelete(e); handleOpenme()}}>
                                <img src={FOTOS + e.photo} alt={e.alt} />
                            </div>
                        )}
                    </div>
                    <button title="Adicionar neste post" className="ed_btn_addft" onClick={() => { handleOpenmd(); setToadd(el); }}>
                        <i className="fa fa-plus fa-lg" aria-hidden="true"> </i>
                    </button>
                </div>
            </div>
            <ModalExcluir open={openme} handleClose={handleCloseme} toDelete={fotoDelete} handleupdated={handleupdated}/>
            <ModalAddFotos open={openmd} handleClose={handleClosemd} toAdd={toadd} handleupdated={handleupdated}/>
        </div>
    const linkinterno = el => <p className="np_linkinterno" key={el?.id}><Link to={"/" + el.aux}>  {el?.content}</Link></p>
    const linkexterno = el => <p className="np_linkexterno" key={el?.id}><a href={el?.aux} target="_blank" rel="noopener noreferrer">{el?.content}</a></p>
    const lista = el => {
        if (el?.id === undefined) { return }
        const novastring = el.content.split("\n")
        const lista = novastring.map((item, i) => {
            return <li key={i}> {item}</li>
        })
        return (<ul className="np_lista" key={Math.random()}> {lista} </ul>)
    }
    
    const mapa = el => {
        return <div className="np_mapa" dangerouslySetInnerHTML={{ __html: el.content }} key={Math.random()} />
    }
    const video = el => {
        return <div className="np_video" dangerouslySetInnerHTML={{ __html: el.content }} key={Math.random()} />
    }

    const objtohtml = (el) => {
        switch (el.content_type) {
            case "title1":
                return title1(el)
            case "title2":
                return title2(el)
            case "title3":
                return title3(el)
            case "paragrafo":
                return paragrafo(el)
            case "foto":
                return foto(el)
            case "fotos":
                return listFotos(el)
            case "lista":
                return lista(el)
            case "linkinterno":
                return linkinterno(el)
            case "linkexterno":
                return linkexterno(el)
            case "mapa":
                return mapa(el)
            case "video":
                return video(el)
            default:
                return
        }
    }
    const renderSubposts = subposts?.map((el) =>
        objtohtml(el)
    )

    const RenderItens = posts?.map((p) =>
        <div className={p.link === posttoadd ? "ed_listitem_active" : "ed_listitem"} key={p.id} >
            <img src={FOTOS + p.photo} alt="" className="ed_listphoto" />
            <b className="ed_listName">{p.name_pt} </b>
            <div className="ed_btnscontainer">
                <button title="Adicionar neste post" className="ed_btn_edit" onClick={() => { handleOpen(); setPosttoadd(p.link); setUpdatemode(false) }}>
                    <i className="fa fa-plus fa-lg" aria-hidden="true"> </i>
                </button>
                <button title="Ver o q tem adicionado" className="ed_btn_ok" onClick={() => { setPosttoadd(p.link); setUpdatemode(false);
                 }}>
                    <i className="fa fa-eye fa-lg" aria-hidden="true"> </i>
                </button>
                <button title="Editar algum item" className="ed_btn_edit" onClick={() => { setPosttoadd(p.link ); setUpdatemode(true) }}>
                    <i className="fa fa-edit fa-lg" aria-hidden="true"> </i>
                </button>
            </div>
        </div>
    )



    return (
        <>
            <div className="ed_lista">
                {RenderItens}
            </div>
            <div className="ed_updatecontainer">
                <ModalAddSub open={open} handleClose={handleClose} posttoadd={posttoadd} handleupdated={handleupdated} toUpdate={toUpdate} />
                <ModalPequeno open={openP} handleClose={handleCloseP} handleupdated={handleupdated} toDelete={toDelete} />
                {!updatemode && <div className="ed_form">
                    <div className="ed_button">
                        {!subposts && <p> Clique no post para exibir aqui</p>}
                        {!!subposts && <button className="ed_btn_cancel" onClick={() => { setSubposts(null); setPosttoadd(null) }}> Cancelar </button>}
                    </div>
                    {!!subposts && renderSubposts}
                </div>}
                {/**************** MODO UPDATE ************************************************/}
                {updatemode && <div className="ed_form">
                    <div className="ed_button">
                        {!subposts && <p> {"<<"} Clique no post para exibir aqui</p>}
                        {!!subposts && <button className="ed_btn_cancel" onClick={() => { setSubposts(null); setPosttoadd(null) }}> Cancelar </button>}
                    </div>
                    {!!subposts && subposts.map((el) =>
                        <div className="ed_editlinetoedit">
                            <div className="ed_contenttext">
                                {el.id} =&gt; 
                                <b> Pt: </b>{el.content} <br/> 
                                <b>En: </b><i>{el.content_en}</i>
                            </div>
                            <div className="ed_btnsedit">
                                <button className="ed_btn_edit" onClick={() => { handleOpen(); setToUpdate(el) }}>
                                    <i className="fa fa-edit fa-lg" aria-hidden="true"> </i>
                                </button>
                                <button className="ed_btn_delete" onClick={() => { handleOpenP(); setToDelete(el) }}>
                                    <i className="fa fa-trash fa-lg" aria-hidden="true"> </i>
                                </button>
                            </div>
                        </div>
                    )}
                </div>}
            </div>
        </>
    )
}
export default UpdatePost