// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Editor from './pages/Editor'
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthContext } from './services/authService';
import 'font-awesome/css/font-awesome.min.css'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState({status:false, nome:"", email:""});

  return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/" element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    } /> */}
                    <Route path="/" element={
                        <ProtectedRoute>
                            <Editor />
                        </ProtectedRoute>
                    } />
                    {/* <Route path="/user" element={
                        <ProtectedRoute>
                            <Users />
                        </ProtectedRoute>
                    } /> */}
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </Router>
        </AuthContext.Provider>
  );
}

export default App;
